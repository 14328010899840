import React from "react";
import { Modal } from "react-responsive-modal";
import ReactPlayer from "react-player";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-responsive-modal/styles.css";
import "../../styles/videoModal.css";
import { Button } from "../../components/Button/Button";
import playIcon from "../../images/icons/largePlayIcon.svg";
import pauseIcon from "../../images/icons/pauseIcon.svg";
import playButton from "../../images/icons/playButton.svg";
import parse from "html-react-parser";

const styles = require("./VideoFeaturePanel.module.scss");

const VideoFeaturePanel = (props: any) => {
  const { data, className, layout = "", buttonClick = null } = props;
  const [open, setOpen] = React.useState(false);
  const [showPause, setShowPause] = React.useState(false);
  const [showPlay, setShowPlay] = React.useState(false);
  const myRef = React.useRef(null);

  const video = data?.video?.video?.localFile?.publicURL;
  const transcript = data?.video?.transcript?.localFile?.publicURL;

  const mainImage =
    data?.mainImage?.localFile?.childImageSharp?.gatsbyImageData;
  const videoPoster =
    data?.video?.poster?.localFile?.childImageSharp?.gatsbyImageData || null;

  const videoPause = () => {
    setShowPause(true);
    setShowPlay(false);
  };

  const videoPlay = () => {
    setShowPause(false);
    setShowPlay(true);
  };

  const launchVideo = () => {
    setOpen(true);
  };

  const layoutClass = styles[layout];

  return (
    <>
      <div>
        {data.heading ? (
          <div className={styles.titleContainer}>
            <img src={data?.headerImage?.localFile?.publicURL} alt="logo" />
            <div className={styles.title}>
              <h2>{data.heading}</h2>
            </div>
          </div>
        ) : null}
        <div className={`${styles.main} ${className}  ${layoutClass}`}>
          <div className={styles.copy}>
            {parse(data.copy)}
            <div className={styles.ctaGroup}>
              {data.cta ? (
                <div className={styles.buttonWrap}>
                  {buttonClick ? (
                    <Button
                      bkgColor="black"
                      label={data.cta.text}
                      icon={data.cta.icon?.localFile.publicURL || null}
                      handleOnClick={buttonClick ? buttonClick : null}
                    />
                  ) : (
                    <Button
                      type="internalLink"
                      label={data.cta.text}
                      bkgColor="black"
                      toPage={data.cta.link}
                    />
                  )}
                </div>
              ) : null}
              {data.cta2 ? (
                <div className={styles.buttonWrap}>
                  <Button
                    type="internalLink"
                    label={data.cta2.text}
                    bkgColor="clearOnLight"
                    toPage={data.cta2.link}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {video ? (
            <div
              className={styles.videoPictureWrap}
              onClick={() => launchVideo()}
            >
              <GatsbyImage
                className={styles.mainImage}
                image={videoPoster ? videoPoster : mainImage}
                alt={data.mainImage.alternativeText}
              />

              <div className={styles.videoWrap}>
                <img
                  src={playButton}
                  alt="launch video"
                  className={styles.videoLaunch}
                />{" "}
              </div>
            </div>
          ) : (
            <div className={styles.videoPictureWrap}>
              <GatsbyImage
                className={styles.mainImage}
                image={mainImage}
                alt={data.mainImage.alternativeText}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        container={myRef.current}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
          closeIcon: "closeIcon",
          closeButton: "closeButton",
        }}
      >
        <div className={styles.playerWrapper}>
          <ReactPlayer
            className={styles.videoPlayer}
            url={video}
            controls
            muted={false}
            width="100%"
            height="calc(100vh - 2.4rem)"
            onPause={videoPause}
            onPlay={videoPlay}
            playing
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
                tracks: [
                  {
                    kind: "subtitles",
                    src: transcript,
                    srcLang: "en",
                    default: false,
                  },
                ],
              },
            }}
          />
          {showPause ? (
            <div className={styles.videoButton}>
              <img src={pauseIcon} alt="video paused" />
            </div>
          ) : null}
          {showPlay ? (
            <div className={styles.videoButton}>
              <img src={playIcon} alt="video playing" />
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default VideoFeaturePanel;
