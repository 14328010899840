// extracted by mini-css-extract-plugin
export var buttonWrap = "VideoFeaturePanel-module--buttonWrap--XwvJM";
export var copy = "VideoFeaturePanel-module--copy--s2b5t";
export var ctaGroup = "VideoFeaturePanel-module--ctaGroup--AtMRA";
export var fadeInOut = "VideoFeaturePanel-module--fadeInOut---4q7-";
export var main = "VideoFeaturePanel-module--main--MzgD9";
export var mainImage = "VideoFeaturePanel-module--mainImage--ImP7Z";
export var page404Title = "VideoFeaturePanel-module--page404-title--Fy14P";
export var playerWrapper = "VideoFeaturePanel-module--playerWrapper--XRnJi";
export var reverseColMobile = "VideoFeaturePanel-module--reverseColMobile--go26n";
export var title = "VideoFeaturePanel-module--title--f2QGL";
export var titleContainer = "VideoFeaturePanel-module--titleContainer--RopFz";
export var videoButton = "VideoFeaturePanel-module--videoButton--NuECS";
export var videoLaunch = "VideoFeaturePanel-module--videoLaunch--krms1";
export var videoPictureWrap = "VideoFeaturePanel-module--videoPictureWrap--X0P8L";
export var videoPlayer = "VideoFeaturePanel-module--videoPlayer--tXMFZ";
export var videoWrap = "VideoFeaturePanel-module--videoWrap--eFhuw";