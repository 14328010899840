import React from "react";
import {
  benefits_container,
  benefits,
  benefit_container,
  image_container,
  content_container,
  buttonWrap,
} from "./Benefits.module.scss";
import parse from "html-react-parser";

import { Button } from "../Button/Button";
const Benefits = ({ benefitsData, faqLink }) => {
  return (
    <div className={benefits_container}>
      <div className={benefits}>
        {benefitsData.map(({ Title, Copy, Icon }, index) => {
          return (
            <div key={index} className={benefit_container}>
              <div className={image_container}>
                <img src={Icon.localFile.publicURL} alt="" />
              </div>
              <div className={content_container}>
                <h4>{Title}</h4>
                <p>{parse(Copy)}</p>
              </div>
            </div>
          );
        })}
      </div>
      {faqLink ? (
        <div className={buttonWrap}>
          <Button
            label={faqLink.text}
            bkgColor="blackOnLight"
            type="internalLink"
            toPage={`/faqs#${faqLink.faq_category.category}`}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Benefits;
